$utilities: map-merge(
  $utilities,
  (
    "border-top": map-merge(
      map-get($utilities, "border-top"),
      (
        values: map-merge(
          map-get(map-get($utilities, "border-top"), "values"),
          (null: 1px solid var(--color-base-bg-3)),
        ),
        responsive: true
      ),
    ),
    "background-color": map-merge(
      map-get($utilities, "background-color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "background-color"), "values"),
          $zs-colors-light-css-vars
        ),
      ),
    ),
  )
);
