.footer {
  padding-top: $section-gutter * 2;
  padding-bottom: $section-gutter * 2;
  background-color: var(--color-footer-bg);
  .footer-info {
    h2,
    h3 {
      font-weight: 700;
      font-size: 23px;
      margin-bottom: 7px;
    }
    p {
      font-size: 16px;
      color: var(--color-base-text-2);
    }
  }
  .footer-info + .social {
    margin-top: 20px;
  }
  .footer-heading {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-base-text);
    margin-bottom: 7px;
  }
  .menu-footer {
    font-size: 15px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        a {
          display: block;
          color: var(--color-base-text);
          text-decoration: none;
          padding: 4px 0 4px 0;
          @include media-breakpoint-up(md) {
            padding: 2px 0 2px 0;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      li.active {
        a {
          font-weight: 700;
        }
      }
    }
  }
}
