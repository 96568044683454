.menu-main-mobile {
  position: fixed;
  background: var(--color-primary);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: 100;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  flex-direction: column;
  .menu-main-mobile-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: $header-height;
  }
  .menu-main-mobile-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
  }
  .menu-main-mobile-bottom {
    padding: $grid-gutter-width;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .menu-main-close {
    color: var(--color-primary-text);
    font-size: 30px;
    padding: 10px $grid-gutter-width;
  }
  &.nested {
    ul {
      font-size: 18px;
      text-align: left;
      li {
        a {
        }
      }
    }
  }
  &.open {
    opacity: 1;
    visibility: visible;
  }
  ul {
    font-size: 22px;
    font-family: $font-family-base;
    color: var(--color-primary-text);
    text-align: center;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    li {
      display: block;
      position: relative;
      flex: 1;
      a {
        display: block;
        padding: 10px $grid-gutter-width;
        color: inherit;
        &:hover {
          opacity: 0.7;
        }
      }
      &.active {
        > a {
          font-weight: bold;
        }
      }
      ul.sub-menu {
        li {
          a {
            padding: 10px $grid-gutter-width + 10px;
          }
        }
      }
    }
  }
  > ul {
    padding: 20px 0;
  }
}
.menu-main-mobile {
  .social {
    color: var(--color-primary-text);
  }
}
.hamburger-trigger {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    display: none;
  }
  &.open {
    position: absolute;
    z-index: 110;
  }
  &:hover {
    opacity: 0.7;
  }
}
.hamburger {
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 0;
  transition: all 0.25s ease-in-out;
  color: var(--color-base-text);
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentcolor;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-in-out;
  }
  &.is-active {
    border-color: transparent;
    color: var(--color-primary-text);
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
.lock-scroll {
  overflow: hidden;
}
